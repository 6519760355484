import { useEffect } from 'react'
import '@/styles/globals.scss'
import 'antd/dist/antd.min.css';

import { wrapper } from '@/store/store'
import Head from 'next/head'
import fav from '@/assets/fav.png'
import Script from 'next/script'

import useReducers from '@/store/user'

function App({ Component, pageProps }) {
  const [, userActions] = useReducers()

  useEffect(() => {
    userActions.getUserInfo()
  }, [])

  return (
    <>
      <Head>
        <link type="image/x-icon" href={fav.src} rel="shortcut icon"/>
        <meta name="huzhan-web-verification" content="2f1e46713f" />
      </Head>
      <Component {...pageProps} />
      <Script src="https://s22.cnzz.com/z_stat.php?id=1273126914&web_id=1273126914&shopic=1"/>
    </>
  )
}

export default wrapper.withRedux(App)
