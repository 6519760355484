import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { useSelector, useDispatch } from 'react-redux'

import http from '@/utils/request'


const extras = {
  getChatMessages: {
    action: createAsyncThunk(
      'creation/getChatMessages',
      async (userId) => {
        const res = await http.get(`/api/demo/chat/creation/${userId}`)
        return {
          [userId]: res || []
        }
      }
    ),
    reducer(state, action) {
      state.messages = {
        ...state.messages,
        ...action.payload,
      }
    }
  }
}

export const slice = createSlice({
  name: 'demo-creation',
  initialState: {
    histories: [],
    active: '',
    messages: {}
  },
  reducers: {
    setHistories: (state, action) => {
      state.histories= action.payload
    },
    setActive: (state, action) => {
      state.active= action.payload
    },
    setMessages: (state, action) => {
      state.messages = {
        ...state.messages,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    Object.keys(extras).forEach(key => {
      const extra = extras[key]
      builder.addCase(extra.action.fulfilled, extra.reducer)
    })

    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    })
  },
})

export default function useReducers() {
  const dispatch = useDispatch()
  const currentState = useSelector(state => state[slice.name])

  const actions = Object.keys(slice.actions).reduce((result, key) => {
    return {
      ...result,
      [key]: (...args) => {
        return dispatch(slice.actions[key](...args))
      }
    }
  }, {})

  Object.keys(extras).forEach(key => {
    const extra = extras[key]
    actions[key] = (...args) => {
      return dispatch(extra.action(...args))
    }
  })

  return [currentState, actions]
}
