import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { useSelector, useDispatch } from 'react-redux'

import http from '@/utils/request'

const extras = {
  getUserInfo: {
    action: createAsyncThunk(
      'user/getUserInfo',
      () => {
        return http.get('/api/user/get')
      }
    ),
    reducer(state, action) {
      state.userInfo = action.payload
    }
  },
  getUserPlan: {
    action: createAsyncThunk(
      'user/getUserPlan',
      () => http.get('/api/user/plan')
    ),
    reducer(state, action) {
      state.plan = action.payload
    }
  },
  getTopups: {
    action: createAsyncThunk(
      'user/getTopups',
      () => http.get('/api/user/topups')
    ),
    reducer(state, action) {
      state.topups = action.payload
    }
  },
  getPlanTopups: {
    action: createAsyncThunk(
      'user/getPlanTopups',
      () => http.get('/api/user/plan-topups')
    ),
    reducer(state, action) {
      state.planTopups = action.payload
    }
  },
  getUserBalance: {
    action: createAsyncThunk(
      'user/getUserBalance',
      () => http.get('/api/user/balance')
    ),
    reducer(state, action) {
      state.balance = action.payload
    }
  }
}

export const slice = createSlice({
  name: 'user',
  initialState: {
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo= action.payload
    },
    setTopups: (state, action) => {
      state.topups = action.payload
    },
  },
  extraReducers: (builder) => {
    Object.keys(extras).forEach(key => {
      const extra = extras[key]
      builder.addCase(extra.action.fulfilled, extra.reducer)
    })

    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    })
  },
})

export default function useReducers() {
  const dispatch = useDispatch()
  const currentState = useSelector(state => state[slice.name])

  const actions = Object.keys(slice.actions).reduce((result, key) => {
    return {
      ...result,
      [key]: (...args) => {
        return dispatch(slice.actions[key](...args))
      }
    }
  }, {})

  Object.keys(extras).forEach(key => {
    const extra = extras[key]
    actions[key] = (...args) => {
      return dispatch(extra.action(...args))
    }
  })

  return [currentState, actions]
}
