import axios from 'axios'

import { message } from 'antd'

const http = axios.create({
  baseURL: '/ai'
})

http.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
  if (error.response.status === 400) {
    message.info(error.response?.data?.message)
  }

  throw error
});
//
export default http


const phpHttp = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? '' : '',
  baseURL: '',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

phpHttp.interceptors.response.use(function (response) {
  if (response.data.status === 1) {
    message.info(response?.data?.msg)
    throw new Error(response?.data?.msg)
  }

  return response.data;
}, function (error) {
  throw error
});

export { phpHttp }
