import { configureStore, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from "next-redux-wrapper";
import { createWrapper } from "next-redux-wrapper";
import { slice as chatSlice } from './chat'
import { slice as creationSlice } from './creation'
import { slice as imageSlice } from './image-generator'
import { slice as rewriteSlice } from './rewrite'
import { slice as reducingSlice } from './reducing'
import { slice as userSlice } from './user'
import { slice as demoCreationSlice } from './demo/creation'
import { slice as topupSlice } from './topup'
import { slice as langCreationSlice } from './long-creation'
import { slice as planSlice } from './plan'
import { slice as guideSlice } from './guide'

export const slice = createSlice({
  name: 'home-chat',
  initialState: {
    messages: []
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
  },
})

const makeStore = () =>
  configureStore({
    reducer: {
      [slice.name]: slice.reducer,
      [chatSlice.name]: chatSlice.reducer,
      [creationSlice.name]: creationSlice.reducer,
      [imageSlice.name]: imageSlice.reducer,
      [rewriteSlice.name]: rewriteSlice.reducer,
      [reducingSlice.name]: reducingSlice.reducer,
      [userSlice.name]: userSlice.reducer,
      [demoCreationSlice.name]: demoCreationSlice.reducer,
      [topupSlice.name]: topupSlice.reducer,
      [langCreationSlice.name]: langCreationSlice.reducer,
      [planSlice.name]: planSlice.reducer,
      [guideSlice.name]: guideSlice.reducer,
    },
    devTools: process.env.NODE_ENV === 'development',
  });

export const actions = slice.actions

export const wrapper = createWrapper(makeStore);
