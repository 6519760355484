import { createSlice,  } from '@reduxjs/toolkit'
import { HYDRATE } from "next-redux-wrapper";
import { createWrapper } from "next-redux-wrapper";
import { useSelector, useDispatch } from 'react-redux'

export const slice = createSlice({
  name: 'image-generator',
  initialState: {
    histories: [],
    active: '',
    messages: {}
  },
  reducers: {
    setHistories: (state, action) => {
      state.histories= action.payload
    },
    setActive: (state, action) => {
      state.active= action.payload
    },
    setMessages: (state, action) => {
      state.messages = {
        ...state.messages,
        [state.active]: action.payload,
      }
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
  },
})

export default function useReducers() {
  const dispatch = useDispatch()
  const currentState = useSelector(state => state[slice.name])

  const actions = Object.keys(slice.actions).reduce((result, key) => {
    return {
      ...result,
      [key]: (...args) => {
        return dispatch(slice.actions[key](...args))
      }
    }
  }, {})

  return [currentState, actions]
}
