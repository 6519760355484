import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { useSelector, useDispatch } from 'react-redux'

import http from '@/utils/request'


const extras = {
  getGoods: {
    action: createAsyncThunk(
      'topup/getGoods',
      () => {
        return http.get(`/api/user/goods`)
      }
    ),
    reducer(state, action) {
      state.goods = action.payload
    }
  }
}

export const slice = createSlice({
  name: 'topup',
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    Object.keys(extras).forEach(key => {
      const extra = extras[key]
      builder.addCase(extra.action.fulfilled, extra.reducer)
    })

    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    })
  },
})

export default function useReducers() {
  const dispatch = useDispatch()
  const currentState = useSelector(state => state[slice.name])

  const actions = Object.keys(slice.actions).reduce((result, key) => {
    return {
      ...result,
      [key]: (...args) => {
        return dispatch(slice.actions[key](...args))
      }
    }
  }, {})

  Object.keys(extras).forEach(key => {
    const extra = extras[key]
    actions[key] = (...args) => {
      return dispatch(extra.action(...args))
    }
  })

  return [currentState, actions]
}
