import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { useSelector, useDispatch } from 'react-redux'

import http from '@/utils/request'

const extras = {
  createTitle: {
    action: createAsyncThunk(
      'long-creation/createTitle',
      async (data, { rejectWithValue }) => {
        try {
          return await http.post(`/api/chat/long-creation/${data.chatId}/title`, data)
        } catch(err) {
          return rejectWithValue(err.response.data)
        }
      }
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg.chatId]: payload || []
      }
    }
  },
  createOutline: {
    action: createAsyncThunk(
      'long-creation/createOutline',
      ({ chatId, title }) => http.post(`/api/chat/long-creation/${chatId}/outline`, { title })
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg.chatId]: payload || []
      }
    }
  },
  reCreateOutline: {
    action: createAsyncThunk(
      'long-creation/re-outline',
      (chatId) => http.post(`/api/chat/long-creation/${chatId}/re-outline`)
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg]: payload || []
      }
    }
  },
  reCreateTitle: {
    action: createAsyncThunk(
      'long-creation/re-title',
      (chatId) => http.post(`/api/chat/long-creation/${chatId}/re-title`)
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg]: payload || []
      }
    }
  },
  checkFunds: {
    action: createAsyncThunk(
      'long-creation/check-funds',
      ({ chatId, type}) => http.post(`/api/chat/long-creation/${chatId}/check-funds`, { type })
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg.chatId]: payload || []
      }
    }
  },
  pickOutline: {
    action: createAsyncThunk(
      'long-creation/pickOutline',
      ({ chatId, outline }) => http.post(`/api/chat/long-creation/${chatId}/pick-outline`, { outline })
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg.chatId]: payload || []
      }
    }
  },
  createArticle: {
    action: createAsyncThunk(
      'long-creation/createArticle',
      (chatId) => http.post(`/api/chat/long-creation/${chatId}/article`)
    ),
    reducer(state, { meta, payload }) {
      state.messages = {
        ...state.messages,
        [meta.arg]: payload || []
      }
    }
  },
  getChatMessages: {
    action: createAsyncThunk(
      'long-creation/getChatMessages',
      async (chatId) => {
        const res = await http.get(`/api/chat/${chatId}`)
        return {
          [chatId]: res || []
        }
      }
    ),
    reducer(state, action) {
      state.messages = {
        ...state.messages,
        ...action.payload,
      }
    }
  }
}

export const slice = createSlice({
  name: 'long-creation',
  initialState: {
    histories: [],
    active: '',
    messages: {},
    streams: {},
  },
  reducers: {
    setStreams: (state, action) => {
      state.streams = action.payload
    },
    setHistories: (state, action) => {
      state.histories= action.payload
    },
    setActive: (state, action) => {
      state.active= action.payload
    },
    setMessages: (state, action) => {
      state.messages = {
        ...state.messages,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    Object.keys(extras).forEach(key => {
      const extra = extras[key]
      builder.addCase(extra.action.fulfilled, extra.reducer)
    })

    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    })
  },
})

export default function useReducers() {
  const dispatch = useDispatch()
  const currentState = useSelector(state => state[slice.name])

  const actions = Object.keys(slice.actions).reduce((result, key) => {
    return {
      ...result,
      [key]: (...args) => {
        return dispatch(slice.actions[key](...args))
      }
    }
  }, {})

  Object.keys(extras).forEach(key => {
    const extra = extras[key]
    actions[key] = (...args) => {
      return dispatch(extra.action(...args))
    }
  })

  return [currentState, actions]
}
